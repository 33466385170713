.artist-page-link {
  cursor: default;

  .artist-avatar {
    cursor: pointer;
  }

  .artist-link {
    border-radius: 50%;
  }
}

.avatar-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.artist-avatar {
  display: block;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  width: 100px;
  height: 100px;
  margin-top: 0;
}

.representedByGallery {
  position: absolute;
  bottom: 0;
  color: #ff1749;
  font-family: 'sul-sans', helvetica, sans-serif;
  font-size: 10px;
  line-height: 20px;
  background-color: #ffffff;
  padding: 0 9px;
  display: inline-block;
  border-radius: 10px;
}
