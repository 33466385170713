@import '../../vars';

.dropdown-select {
  .form-control {
    position: relative;
    padding-right: calc(5px + 18px + #{$input-padding-y});
    text-align: left;
    cursor: pointer;
  }

  .bi-caret {
    position: absolute;
    top: 0;
    right: $input-padding-y;
  }

  .dropdown-item {
    cursor: pointer;
  }

  .dropdown-menu {
    overflow-y: auto;
    max-height: 500px;
  }
}
