@import '../../vars';

.rating {
  .bi {
    display: inline-block;
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }
  }
}
